import React from "react";
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";
import { useButton } from "../../../../../contexts/ButtonContext";
import { Button, Card, Table, Placeholder, Accordion } from "react-bootstrap"

export function Requests() {    //TODO: Automatically deny conflicting requests
    const { dbUser, requests, getItemByPath } = useAuth()
    const { removeRequest, approveRequest, showItemDetailsModal, showRequestDetailsModal } = useButton()
    const { loading, flattenObject } = useHelp();

    if(dbUser.isAdmin) {
        return(
            <Accordion.Item eventKey="REQUESTS">
                <Accordion.Header><h5>Requests</h5></Accordion.Header>
                <Accordion.Body>
                    <h3 align="center" className="mb-3">Requests</h3>
                    <Table responsive>
                        <tbody>
                        {flattenObject(requests) && Object.entries(flattenObject(requests))?.sort().map((request) => {
                            return(
                                <tr key={request[0]}>
                                    <td><strong>{String(request[1].User)}</strong> would like to <i>{String(request[1]["Outstanding Request"])}</i> <strong>{String(request[1].Name)}</strong></td>
                                    <td><Button variant="warning" disabled={loading} onClick={() => {approveRequest(request[1])}}>Confirm</Button></td>
                                    <td><Button variant="warning" disabled={loading} onClick={() => {removeRequest(request[1])}}>Deny</Button></td>
                                    <td><Button variant="secondary" disabled={loading} onClick={async () => {showItemDetailsModal(await getItemByPath(request[1].Path))}}>Item Details</Button></td>
                                    <td><Button variant="secondary" disabled={loading} onClick={() => {showRequestDetailsModal(request[1])}}>Request Details</Button></td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                    {!(Object.entries(flattenObject(requests))?.length) && <p className="mt-4" align="center">Your club does not currently have any equipment requests.</p>}
                    {loading && <Placeholder as={Card.Body} animation="glow">
                        <p><Placeholder xs={12} aria-hidden="true"/></p>
                        <p><Placeholder xs={12} aria-hidden="true"/></p>
                    </Placeholder>}
                </Accordion.Body>
            </Accordion.Item>
        )
   }
}