import React, { Component } from 'react'

class ErrorBoundary extends Component {

    constructor(props) {
        super(props)

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }
  render() {
    if (this.state.hasError) {
        return <h1>Apologies! Something went wrong on our end. We are currently working to resolve this issue. If the issue persists, please contact your club administrator.</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary