import React from "react";
import { Button, Card, Form } from "react-bootstrap"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useButton } from "../../../../../contexts/ButtonContext";
import { useHelp } from "../../../../../contexts/HelperContext";
    
export function InventoryImportExport(){
    const { dbUser } = useAuth()
    const { downloadInventory, uploadInventory, setInventoryFile} = useButton()
    const { loading, setCurrentModal, setModalTitle, setModalBody, setModalForm } = useHelp()

    function inventoryImportModal() {
        setModalTitle("Clear existing items?")
        setModalBody("If you would like to delete all existing equipment records (including requests) before uploading your selected inventory file, choose 'Clear and Upload'. If you would like to update your existing equipment information, retaining all item information not present in your current upload, choose 'Upload Only'.")
        setModalForm(<>
            <Button variant="info" onClick={() => {setCurrentModal(""); downloadInventory()}}>Backup First</Button>
            <Button variant="secondary" onClick={() => {setCurrentModal(""); uploadInventory(false)}}>Upload Only</Button>
            <Button variant="primary" onClick={() => {setCurrentModal(""); uploadInventory(true)}}>Clear and Upload</Button>
            </>
        )
        setCurrentModal("inventoryImport");
    }

    if(dbUser.isAdmin) {
        return(
            <Card>
                <Card.Body>
                <h2 className="text-center mb-4">Inventory Data</h2>
                    <div align="center">
                        <strong>Upload Updated Inventory File (xlsx)</strong>
                        <Form className="d-flex" id="inputForm" onSubmit={(event) => {inventoryImportModal(); event.preventDefault();}}>
                            <input className="mt-3" type="file" required  accept=".xlsx"
                                onChange={(e) => {setInventoryFile(e.target.files[0])}}/>
                            <Button variant="warning" className="mt-3" disabled={loading} type="submit">Upload</Button>
                        </Form>
                        <Button variant="warning" className="w-100 mt-3" disabled={loading} onClick={downloadInventory}>Download Inventory File (xlsx)</Button>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}


