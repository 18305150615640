import React, { useRef } from "react"
import { Container, Form, Button, Card } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import { Link } from "react-router-dom"
import { useHelp } from "../../../contexts/HelperContext"

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const { loading } = useHelp()

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }} >
        <div className="w-100" style={{ maxWidth: "400px" }}>
            <Card>
                <Card.Body>
                <h2 className="text-center mb-4">Password Reset</h2>
                <Form onSubmit={(e) => {e.preventDefault(); resetPassword(emailRef.current.value);}}>
                    <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">
                    Reset Password
                    </Button>
                </Form>
                <div className="w-100 text-center mt-3">
                    <Link to="/login">Login</Link>
                </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Need an account? <Link to="/register">Sign Up</Link>
            </div>
        </div>
    </Container>
  )
}