import React, { useContext, useState } from "react"
import { deleteField, Timestamp, FieldValue } from "firebase/firestore";
import { useAuth } from "./AuthContext"
import { useHelp } from "./HelperContext"
import { Button, Form, Row, Col, Table } from "react-bootstrap"
import * as XLSX from 'xlsx'

//TODDO: Add promises to async functions here

const ButtonContext = React.createContext()

export function useButton() {
  return useContext(ButtonContext)
}

export function ButtonProvider({ children }) {
    const { currentUser, dbUser, items, requests, clubInfo, roster, updateDocument, addField, getItemByPath, getSetupSheet, getReservers, clearItems, createXLSX } = useAuth();
    const [extraInputs, setExtraInputs] = useState(0);
    const [newItemType, setNewItemType] = useState("");
    const { setError, setSuccess, setLoading, currentModal, setCurrentModal, setModalTitle, setModalBody, setModalForm, getName, flattenObject, buildSearchQuery } = useHelp()
    const [inventoryFile, setInventoryFile] = useState()
    //const [itemInfo, setItemInfo] = useState({})
    //const [itemType, setItemType] = useState("")
    //const [item, setSelectedItem] = useState({})

    const value = {
        selectItem,
        //item,
        handleResponse,
        createRequest,
        removeRequest,
        approveRequest,
        //setItemInfo,
        //setItemType,
        AddInput,
        showItemDetailsModal,
        showRequestDetailsModal,
        showReturnLocationModal,
        showAdvancedSearchModal,
        sendDiscordMessage,
        downloadInventory,
        setInventoryFile,
        uploadInventory
    }

    //useEffect(() => {
        //console.log(currentModal)
    //}, [currentModal])

    function selectItem(info) {   //BUG: Remove this function. Maybe add all these fields on upload.
        const itemName = info.Name ? info.Name : String(info.Manufacturer) + " " + String(info.Model) + " #" + String(info.ID)
        const itemPath = "items/" + String(dbUser.Club) + "/" + String(info.Type) + "/" + itemName;
        //setSelectedItem({Name: itemName, Type: String(type), Path: String(itemPath), ...info})
        return {Name: itemName, Path: String(itemPath), ...info};
    }

    async function handleResponse(item, response, modalType) {
        setCurrentModal("");
        setLoading(true);
        //Combine all form response info into one data structure
        const formData = Object.fromEntries(new FormData(response.target));
        const checkOut = response.nativeEvent.submitter.name === 'true';
        let userPath = "";
        let userData = {};
        let itemData = {};
        //Perform correct action based on form type submitted
        switch(modalType) {
            case "Assign":
                //Assign item to selected user
                const reserverData = roster[formData.reserver];
                userPath = "users/" + formData.reserver;
                userData = { "reserved" : {[item.Name] : [item.Type, checkOut]} };
                itemData = { "Reserver" : reserverData["Email"] }
                if(checkOut){
                    //If checked-out, change location in database to person's email
                    itemData = {...itemData, "Location" : reserverData["Email"]};
                }
                break;
            case "Add":
                //Add item to database based on entered attributes
                item.Path = "items/" + String(dbUser.Club) + "/" + String(formData.Type) + "/" + getName(formData);
            case "Edit":
                //Update the item to match submitted fields
                itemData = formData;
                break;
            case "returnLocation":
                //Don't try to modify users named "TBD" or "Broken" or null
                if ((item["Reserver"] && item["Reserver"] !== "TBD" && item["Reserver"] !== "Broken") || (item?.old?.item["Reserver"] && item?.old?.item["Reserver"] !== "Broken" && item?.old?.item["Reserver"] !== "TBD")) {
                    //Remove reserved item from user profile. item.old.item for when admin gets modal while confirming return request
                    userPath = item["Reserver"] ? "users/" + item["Reserver"] : "users/" + item.old.item["Reserver"];
                    userData = {
                        "reserved" : {
                            [item.Name] : deleteField()
                        }
                    }
                }
                if (response.target[0].value === "TBD" || response.target[0].value === "Broken") {
                    //Change location and reserver in database to reflect broken/lost
                    itemData = {
                        "Location" : response.target[0].value,
                        "Reserver" : response.target[0].value,
                    }
                }
                else {
                    //Change location in database to new location and clear reserver details
                    itemData = {
                        "Location" : response.target[0].value,
                        "Reserver" : null,
                    }
                }
                break;
            default:
                break;
        }

        return new Promise(async (resolve, reject) => {
            await updateDocument(item.Path, itemData)
            .then(async () => {
                if(userPath !== ""){
                    await updateDocument(userPath, userData);
                }
            })
            .then(() => {
                resolve('response handled')
            })
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Something went wrong. Please contact your administrator");
                reject(err.code);
            })
            .finally(() => {
                setLoading(false);
            })

            
        })
    }

    async function createRequest(item, action, requestorName, requestorEmail, requestorUserPath, requestTime) {
        setCurrentModal("");
        setLoading(true);
        //requestorName, requestorEmail, requestorUserPath, requestTime parameters are optional. Used for uploading requests
        const currentDate = new Date();
        currentDate.setMilliseconds(0);
        const requestTimestamp = (requestTime ? Timestamp.fromDate(requestTime) : Timestamp.fromDate(currentDate));
        const requestPath = "requests/" + String(dbUser.Club) + "/" + String(action) + "/" + String(requestTimestamp.toMillis()) + "->" + String(action) + "->" + String(item.Name);
        const userName = requestorName || dbUser.Name
        const userEmail = requestorEmail || currentUser.email
        const userPath = requestorUserPath || "users/" + String(currentUser.email);
        let requestData = {"Name" : item.Name, "Path" : item.Path, "User" : userName, "User Email" : userEmail, "User Path" : userPath, "Outstanding Request" : action, "Request Time" : requestTimestamp }
        switch(action) {
            case "CHECK-IN":
            case "MOVE":
                requestData = { ...requestData,
                    old: { item: {"Location" : item.Location}, user: { "reserved": {[item.Name]:[item.Type ,true] } } },
                    new: { item: {"Location" : item.newLocation}, user: { "reserved": {[item.Name]:[item.Type ,false] } } }
                }
                break;
            case "CHECK-OUT":
                requestData = { ...requestData,
                    old: { item: {"Location" : item.Location}, user: { "reserved": {[item.Name]:[item.Type ,false] } } },
                    new: { item: {"Location" : userEmail}, user: { "reserved": {[item.Name]:[item.Type ,true] } } }
                }
                break;
            case "RESERVE":
                requestData = { ...requestData,
                    old: { item: {"Reserver" : null}, user: { "reserved": {} } },
                    new: { item: {"Reserver" : userEmail}, user: { "reserved": {[item.Name]:[item.Type ,false] } } }
                }
                break;
            case "RESERVE-OUT":
                //Reserve and check-out
                requestData = { ...requestData,
                    old: { item: {"Reserver" : null, "Location" : null}, user: { "reserved": {} } },
                    new: { item: {"Reserver" : userEmail, "Location" : userEmail}, user: { "reserved": {[item.Name]:[item.Type ,true] } } }
                }
                break;
            case "RETURN":
                requestData = { ...requestData,
                    old: { item: {"Location" : item.Location, "Reserver" : userEmail}, user: {"reserved": {[item.Name]:[item.Type ,true] }} },
                    new: { item: {"Location" : null, "Reserver" : null}, user: {"reserved": {[item.Name] : null} } }
                }
                break;
            default:
                break;
        }

        return new Promise(async (resolve, reject) => {
            await updateDocument(requestPath, requestData)
            .then(async () => {
                await updateDocument(item.Path, {"Outstanding Request" : action, "Requestors" : {[userEmail] : requestTimestamp} });
            })
            .then(() => {
                sendDiscordMessage({"userName" : userName, "userEmail" : userEmail, "Item" : item.Name, "Action": action, "Location" : item.Location}, "REQUEST");
                setSuccess("Request Successfully Submitted");
                setError("");
                resolve('request submitted');
            })
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Failed to submit request. Please contact your administrator");
                reject(err.code);
            })
            .finally(() => {
                setLoading(false);
            })
        })
    }

    async function removeRequest(item) {
        //ADD: Allow multiple reserve requests for an available item. Currently this does not work because "Outstanding Request" field gets cleared on first removal
        setLoading(true);
        const requestorEmail = item["User Email"] ? item["User Email"] : dbUser.Email;
        const requestItemDetails = {"Outstanding Request" : deleteField(), "Requestors" : {[requestorEmail] : deleteField()} };
        const requestTimestamp = item["Requestors"]?.[requestorEmail]?.toMillis() || item["Request Time"]?.toMillis() || 0
        const requestPath = "requests/" + String(dbUser.Club) + "/" + String(item["Outstanding Request"]) + "/" + String(requestTimestamp) + "->" + String(item["Outstanding Request"]) + "->" + String(item.Name);
        return new Promise(async (resolve, reject) => {
            try {                
                await updateDocument(requestPath, null);
                await updateDocument(item.Path, requestItemDetails);
                setSuccess("Request Successfully Removed");
                setError("");
                resolve('request removed');
            }
            catch (err) {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Failed to remove request. Please contact your administrator");
                reject(err.code);
            }
            setLoading(false);
        })
    }

    async function approveRequest(request) {
        //ADD: After enabling mutiple reserve requests on an available item, clear all other requests once a request is granted
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            try {
                if(request["Outstanding Request"] === "RETURN") {
                    //Turn null value into field deletion - I believe this already happens in handleResponse
                    //request.new.user.reserved[request.Name] = deleteField();
                    showReturnLocationModal(request);
                }
                else {
                    await updateDocument(request.Path, request.new.item);
                    await updateDocument(request["User Path"], request.new.user);
                    await removeRequest(request);
                }
                setSuccess("Request Successfully Approved");
                setError("");
                resolve('request approved')
            }
            catch (err) {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Failed to approve request. Please contact your administrator");
                reject(err.code);
            }
            setLoading(false);
        })
    }

    /*****************Shared Modals***************************/
    function AddInput() {
        const rows = [];
        for (let i = 0; i < extraInputs; i++) {
            rows.push(
            <Form.Group as={Row} className="mb-2" id={i} key={i}>
                <Col>
                    <Form.Control
                        name={String(i) + ".key"}
                        type="text"
                        placeholder="Field Name (Excluding ':')"
                    />
                </Col>
                <Col>
                    <Form.Control
                        name={String(i) + ".val"}
                        type="text"
                        placeholder="Value"
                    />
                </Col>
            </Form.Group>)}
        return rows;
    }

    function showItemDetailsModal(item) {
        const selectedItem = {...item};
        setModalTitle(item.Name)
        //Delete unnecessary/unwanted/duplicate data
        delete item.Name;
        delete item.Path;
        delete item["Requestors"];
        delete item.ID;

        //Only show type for requests
        if(!item["Outstanding Request"]) {
            delete item.Type;
        }

        //Hide reserver email
        if(item.Location.includes('@')) {
            item.Location = 'Checked Out';
        }
        //Show name and email if Admin
        if(dbUser.isAdmin && item["Reserver"]?.includes("@")) {
            const reserver = roster[item["Reserver"]];
            item.Reserver = String(reserver.Name) + " (" + String(reserver.Email) + ")";
        }
        else {
            //Do not show name or email if not admin
            delete item["Reserver"];
        }        
        
        setModalBody(
            <>
            <Table>
                <tbody>
                {
                Object.entries(item).sort().map((field) => {
                    if(field[0] !== "Request Time"){
                        return(
                            field[1] && <tr key={field[0]}>
                                <td><strong>{field[0]}:</strong></td>
                                <td>{field[1]}</td>
                            </tr>
                        )
                    }
                    else {return}
                })}
                </tbody>
            </Table>
            </>
        )
        setModalForm(
            <>
            <Button onClick={() => {setCurrentModal("");}}>Dismiss</Button>
            {dbUser.isAdmin && <Button variant="warning" onClick={() => {showEditModal(selectedItem);}}>Edit/Add Details</Button>}
            </>
        )
        setCurrentModal("details");
    }

    function showRequestDetailsModal(request) {
        setModalTitle(request.Name + " - " + request["Outstanding Request"] )
        setModalBody(
            <><Table>
                <thead><tr><th/><th>Old</th><th>New</th></tr></thead>
                <tbody>
                    {request.old && Object.keys(request.old.item).sort().map((field) => {
                        return(
                            field[1] && <tr key={field}>
                                <td><strong>{field}:</strong></td>
                                <td>{request.old.item[field] ? request.old.item[field] : "Null"}</td>
                                <td>{request.new.item[field] ? request.new.item[field] : "Null"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {request["Request Time"] && <div className="text-center"><strong>Request Time:</strong> {request["Request Time"]?.toDate().toLocaleString()}</div> /*This converts the DB time into user's locale time*/}
            </>
        )
        setModalForm(
            <>
            <Button onClick={() => {setCurrentModal("");}}>Dismiss</Button>
            </>
        )
        setCurrentModal("details");
    }

    function showEditModal(item) { //TODO: Remove any fields that should not be able to be edited manually
        const omitFromEdits = ["Manufacturer","Model","ID","Reserved By","Reserver", "Location", "Requestors", "Outstanding Request", "Path", "Type"];
        setModalTitle(<>{String(getName(item))}     {/*!item["Outstanding Request"] && !item["Reserver"] && <Button variant="danger" onClick={() => {}}>Delete Item</Button>*/}</>); //TODO: Build delete button
        setModalBody("Modify the values below to edit this item. To modify reservation information, use 'Assign' or 'Reclaim' instead.");
        setModalForm(
        <Form id="form" onSubmit={(event) => {handleResponse(selectItem(item), event, "Edit"); event.preventDefault()}}>
            {item && Object.entries(item).map((field) => {
                if(!omitFromEdits.includes(field[0])){
                    return(
                        <Form.Group as={Row} className="mb-2" id={field[0]} key={field[0]}>
                            <Form.Label column><strong>{field[0]}:</strong></Form.Label>
                            <Col><Form.Control name={field[0]} type="text" defaultValue={field[1]}/></Col>
                        </Form.Group>
                    );
                }
                else {return null;}
            })}
            <AddInput/>
            {/*</Form><Row className="mb-2"><Button variant="secondary" onClick={() => {/*TODO: Make add field button work*/ /*setExtraInputs(extraInputs + 1); addInput();*//*}}>Add Field</Button></Row>*/}
            <Row className="col-auto"><Button type="submit">Save</Button></Row>
        </Form>);
        setCurrentModal("edit");
    }

    //BUG: Remove request runs after handle response and hence the success message is weird
    function showReturnLocationModal(item) {
        setModalTitle("Where are you returning this item to?");
        setModalBody("Please select the location where this equpment item will be stored after it is returned.");
        setModalForm(
        <Form id="form" onSubmit={async (event) => {event.preventDefault(); await handleResponse(selectItem(item), event, "returnLocation"); await removeRequest(item);}}>
            <Row className="d-flex align-items-center">
                <Col><Form.Label ><strong>Location:</strong></Form.Label></Col>
                <Col>
                    <Form.Group id="location">
                    <Form.Control as="select" required >
                        {clubInfo?.["Storage Locations"]?.split(',').concat(["TBD", "Broken"]).map( (loc) => {
                            return (<option key={loc} value={loc}>{loc}</option>);
                        })}
                    </Form.Control>
                    </Form.Group>
                </Col>
                <Col className="col-auto">
                    <Button type="submit">Return</Button>
                </Col>
            </Row>
        </Form>);
        setCurrentModal("returnLocation");
    }

    function showAdvancedSearchModal(item, setSearch) {
        //Delete unwanted search fields
        delete item.Type;
        delete item.Path;
        delete item["Requestors"];
        //delete item["Outstanding Request"];

        //Do not allow for search by reserver if not Admin
        if(!dbUser.isAdmin) {
            delete item["Reserver"];
        }
        //    const reserver = roster[item["Reserver"]];
        //    item.Reserver = String(reserver.Name) + " (" + String(reserver.Email) + ")";
        
        setModalTitle("Advanced Search");
        setModalBody("Use the fields below to filter your search. Limit responses to a single word. Do not include units for numerical values.");
        setModalForm(
        <Form id="AdvancedSearchForm" onSubmit={(event) => {setSearch(buildSearchQuery(event)); event.preventDefault(); setCurrentModal("")}}>
            <Form.Group as={Row} className="mb-2">
                {item && Object.keys(item).sort().map((field) => {
                    //Remove range/list text from field names
                    field = field.replace(/ range/i,'');
                    field = field.replace(/\(s\)/i,'');
                    if(field === "Reserver") {
                        return(
                            <Form.Group as={Row} className="mb-2" id={field} key={field}>
                                <Form.Label column><strong>{field}:</strong></Form.Label>
                                <Col><Form.Control as="select" name={field}>
                                    <option key="" value=""></option>
                                    {roster && Object.entries(roster).sort((member1, member2) => {return (member1[1].Name).localeCompare(member2[1].Name)}).map((member) => {
                                        return (<option key={member[0]} value={member[0]}>{String(member[1].Name) + " (" + String(member[0]) + ")"}</option>);
                                    })}
                                </Form.Control></Col>
                            </Form.Group>
                        )
                    }
                    else if(field === "Location") {
                        return(
                            <Form.Group as={Row} className="mb-2" id={field} key={field}>
                                <Form.Label column><strong>{field}:</strong></Form.Label>
                                <Col><Form.Control as="select" name={field}>
                                    <option key="" value=""></option>
                                    {clubInfo?.["Storage Locations"]?.split(',').concat(["TBD", "Broken"]).map( (loc) => {
                                        return (<option key={loc} value={loc}>{loc}</option>);
                                    })}
                                </Form.Control></Col>
                            </Form.Group>
                        )
                    }
                    else if(field === "Outstanding Request") {
                        return(
                            <Form.Group as={Row} className="mb-2" id={field} key={field}>
                                <Form.Label column><strong>{field}:</strong></Form.Label>
                                <Col><Form.Control as="select" name={field}>
                                    <option key="" value=""></option>
                                    <option key="RESERVE" value="RESERVE">RESERVE</option>
                                    <option key="RESERVE-OUT" value="RESERVE-OUT">RESERVE-OUT</option>
                                    <option key="RETURN" value="RETURN">RETURN</option>
                                    <option key="CHECK-IN" value="CHECK-IN">CHECK-IN</option>
                                    <option key="CHECK-OUT" value="CHECK-OUT">CHECK-OUT</option>
                                    <option key="MOVE" value="MOVE">MOVE</option>
                                </Form.Control></Col>
                            </Form.Group>
                        )
                    }
                    else{
                        return(
                            <Form.Group as={Row} className="mb-2" id={field} key={field}>
                                <Form.Label column><strong>{field}:</strong></Form.Label>
                                <Col><Form.Control name={field} type="text"/></Col>
                            </Form.Group>
                        );
                    }
                })}
            </Form.Group>
            <Row>
                <Col><Button className="w-100" variant="primary" type="submit">Search</Button></Col>
                <Col><Button className="w-100" variant="secondary" onClick={() => setCurrentModal("")}>Cancel</Button></Col>
            </Row>
        </Form>);
        setCurrentModal("advancedSearch");
    }

    function sendDiscordMessage(data, type) {
        setLoading(true);
        //If no Discord URL defined for a club
        if (!clubInfo["Discord Webhook URL"]) {
            if(type === "MESSAGE") {
                setSuccess("");
                setError("Your club admin has not yet setup messaging on this platform.");
            }
            return
        }

        let message = {};
        if(type === "REQUEST") {
            message = {
                "content" : String(data.userName) + " would like to " + String(data.Action) + " " + String(data.Item),
                "embeds" : [{
                    "title": "New Request",
                    "fields": [
                        { "name" : "User Name:", "value" : data.userName},
                        { "name" : "User Email:", "value" : data.userEmail},
                        { "name" : "Request Action:", "value" : data.Action},
                        { "name" : "Request Item:", "value" : data.Item},
                        { "name" : "Item Current Location:", "value" : data.Location},
                    ],
                    "url" : "https://gear.uclaarchery.com"
                }]
            }
        }
        else if(type === "MESSAGE") {
            message = {
                "content" : String(dbUser.Name) + ' sent you a message: \n"' + String(data.message) + '"',
                "embeds" : [{
                    "title": "New Message",
                    "fields": [
                        { "name" : "User Name:", "value" : dbUser.Name},
                        { "name" : "User Email:", "value" : dbUser.Email},
                        { "name" : "Message:", "value" : '"' + String(data.message) + '"'},
                    ],
                    "url" : "https://gear.uclaarchery.com"
                }]
            }
        }
        
        return new Promise(async (resolve, reject) => {
            try {
                await fetch(clubInfo["Discord Webhook URL"], {
                    method: 'POST',
                    body: JSON.stringify(message),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                setError("");
                if(type === "MESSAGE") setSuccess("Message sent successfully. An equipment manager will contact you soon.");
                resolve("message sent")
            }
            catch (err) {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                if(type === "MESSAGE") setError("Message failed to send");
                reject(err.code);
            }
            setLoading(false)
        })
    }

    /*****************Inventory Upload/Download Button Functions***************************/
    function downloadInventory() {
        //ADD: Bold Headers. Community fork with styling required - https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling
        const inventory = createXLSX({...items?.INVENTORY, 'REQUESTS' : flattenObject(requests)})
        XLSX.writeFile(getSetupSheet(inventory), "Inventory.xlsx");
        setSuccess("Successfuly downloaded inventory file!");
        setError("")
        window.scrollTo(0, 0);
    }

    async function uploadInventory(clear) {
        setError("");
        setSuccess("");
        setLoading(true);
        let numOmitted = 0;
    
        try {
            if (clear) {
                await clearItems();
            }
    
            const buff = await inventoryFile.arrayBuffer();
            const sheets = XLSX.read(buff, {cellDates : true});
    
            const numSheets = sheets.SheetNames.length;
            const clubName = sheets.Sheets["SETUP"] ? sheets.Sheets["SETUP"]['A4'].v : dbUser.Club;
            let userUpdates = {};

            let clubRequests = {};
    
            //Parse Sheets
            for(let i = 0; i < numSheets; i++) {
                const sheetName = sheets.SheetNames[i];
                const sheet = sheets.Sheets[sheetName];
                if (sheetName === "SETUP") {
                    //Get club info
                    const clubInfo = XLSX.utils.sheet_to_json(sheet, {range: 'A3:G4'});
                    const clubPath = "items/" + String(clubName);
                    await updateDocument(clubPath, clubInfo[0]);
                    //Get admmin info
                    const adminInfo = XLSX.utils.sheet_to_json(sheet, {range: 'A7:D17'});
                    await updateDocument(clubPath, { "admins" : adminInfo });
                    // deepcode ignore ArrayMethodOnNonArray: Snyk incorrectly identified this as not an array
                    await Promise.all(adminInfo.map((admin) => {
                        const adminUserPath = "users/" + String(admin.Email);
                        return userUpdates[adminUserPath] = {...userUpdates[adminUserPath], ...admin, "isAdmin" : true, "Club" : clubName};     //BUG: Not sure if this is a bug
                    }))
                    //Get roster info
                    const rosterInfo = XLSX.utils.sheet_to_json(sheet, {range: 'I3:L999'});
                    await Promise.all(rosterInfo.map((user) => {
                        const userPath = "users/" + String(user.Email);
                        return userUpdates[userPath] = {...userUpdates[userPath], ...user, "Club" : clubName};     //BUG: Not sure if this is a bug
                    }))
                    
                    continue;
                }
                if( sheetName === "REQUESTS" ) {
                    //Get requests to use later
                    clubRequests = XLSX.utils.sheet_to_json(sheet);
                    continue;
                }
                if(!sheet['!ref']) {
                    //Ignore empty sheets
                    console.log("Skipped empty sheet, '" + String(sheetName) + "'")
                    continue;
                }
                const sanitizedSheet = sanitize(sheet, "#N/A", null);   //Change #N/As to nulls //ADD: See if this is possible on import instead of manually
                const templateFormat = !sanitizedSheet['A1'] || sanitizedSheet['A1'].v !== "Manufacturer";   //If upload includes guidance row from template, skip that row in upload
                const items = XLSX.utils.sheet_to_json(sanitizedSheet, {range: templateFormat ? 1 : 0, blankrows: false, defval:null})
                if (items.length === 0) {continue;}
                const itemsPath = "items/" + String(clubName) + "/" + String(sheetName)
                await Promise.all(items.map(async (item) => {
                    if (!item.ID || !item.Model || !item.Manufacturer || item.ID.includes('/') || item.Model.includes('/') || item.Manufacturer.includes('/')) {
                        numOmitted++;
                        return Promise.resolve();
                    }
                    const uniqueID = getName(item);

                    if (item["Reserver"]) {
                        const path = "users/" + String(item["Reserver"]);
                        const data = {
                            "reserved" : {
                                ...{[uniqueID] : [sheetName, (item.Location === item["Reserver"])]}
                            }
                        }
                        if(userUpdates[path]) {userUpdates[path]["reserved"] = {...userUpdates[path]["reserved"], ...data["reserved"]};}
                        else {userUpdates[path] = data;}   //If userUpdates.path is empty, don't try to add on to an undefined "reserved" object
                    }
                    else if (!item["Reserver"]) {
                        const reservers = await getReservers(uniqueID); //Get reservers of this specific item
                        reservers.docs.forEach((reserver) => {
                            const path = "users/" + String(reserver.data().Email);
                            const data = {
                                "reserved" : {
                                    [uniqueID] : deleteField()
                                }
                            }
                            if(userUpdates[path]) {userUpdates[path]["reserved"] = {...userUpdates[path]["reserved"], ...data["reserved"]};}
                            else {userUpdates[path] = data;}   //If userUpdates.path is empty, don't try to add on to an undefined "reserved" object
                        })
                    }
                    
                    //Delete formula-generated reserver details if present
                    delete item['Reserver Name'];
                    delete item['Reserver Phone Number'];
                    delete item['Reserver UID'];

                    //Delete request details since they will be auto-created
                    delete item['Requestors'];
                    delete item['Outstanding Request'];

                    //Delete path       //BUG: This may be incorrect or duplicative
                    delete item['Path'];

                    //Add Item Type to DB
                    item.Type = sheetName;

                    return updateDocument(itemsPath + "/" + String(uniqueID), item);
                }));
            }

            Object.entries(userUpdates).forEach(async (user) => {
                //Don't try to update users named "Broken" or "TBD"
                if (user[0] != "users/Broken" && user[0] != "users/TBD") {
                    await updateDocument(user[0], user[1]);
                }
            })
            //Regenerate requests
            await Promise.all(Object.values(clubRequests).map(async (request) => {
                console.log(await getItemByPath(request.Path))
                return createRequest(selectItem(await getItemByPath(request.Path)), request["Outstanding Request"], request["User"], request["User Email"], request["User Path"], request["Request Time"])
            }))


            if(numOmitted === 0) {setSuccess("Successfuly uploaded inventory file!"); setError(""); window.scrollTo(0, 0);}
            else {setError("All items must have 'Manufacturer's, 'Model's, and 'ID's and must NOT include a slash ('/') in these fields. Offending items have been omitted from this upload. If you would like to include these items, please resolve and upload again. Number of items omitted: " + String(numOmitted)); window.scrollTo(0, 0);}
        }
        catch(err) {
            setSuccess("")
            if (!inventoryFile) {
                setError("Please 'Choose File', then try 'Upload' again");
                window.scrollTo(0, 0);
            }
            else {
                
                setError("Failed to upload inventory file");
                window.scrollTo(0, 0);
            }
            console.log(err);
            console.log(err.message);
            //"sheets.Sheets.SETUP is undefined"
        }
        
        //Clear form
        //document.getElementById("inputForm").reset();
        setInventoryFile();
        setLoading(false);
    }

    function sanitize(dirtySheet, oldtext, newtext) {
        let sheet = dirtySheet;
        /* loop through every cell manually */
        const range = XLSX.utils.decode_range(sheet['!ref']); // get the range
        for(let R = range.s.r; R <= range.e.r; ++R) {
            for(let C = range.s.c; C <= range.e.c; ++C) {
                /* find the cell object */
                const cellref = XLSX.utils.encode_cell({c:C, r:R}); // construct A1 reference for cell
                if(!sheet[cellref]) continue; // if cell doesn't exist, move on
                let cell = sheet[cellref];
    
                /* if the cell is a text cell with the old string, change it */
                if(!(cell.t === 's' || cell.t === 'str')) continue; // skip if cell is not text
                if(cell.v === oldtext) cell.v = newtext; // change the cell value
            }
        }
        return sheet;
    }

    return (
        <ButtonContext.Provider value={value}>
            {children}
        </ButtonContext.Provider>
      )
}