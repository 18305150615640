import React, { useContext, useState, useEffect } from "react"
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, updatePassword, sendPasswordResetEmail, reauthenticateWithCredential, signOut } from "firebase/auth";
import { doc, setDoc, updateDoc, deleteDoc, deleteField, collection, query, where, or, getDoc, getDocs, onSnapshot, orderBy, FieldPath } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { auth, store, functions } from "../firebase"
import * as XLSX from 'xlsx'
import { useHelp } from "./HelperContext"
import { useNavigate } from "react-router-dom"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [dbUser, setdbUser] = useState({})
  const [clubs, setClubs] = useState({})
  const [clubInfo, setClubInfo] = useState({})
  const [roster, setRoster] = useState({})
  const [items, setItems] = useState({})
  const [requests, setRequests] = useState({});
  const { error, setError, setSuccess, loading, setLoading } = useHelp();
  const navigate = useNavigate();

    /*==================================================State & Variable Updates==================================================*/
    useEffect(() => {
        let unsubscribes = [];
        unsubscribes.push(onAuthStateChanged(auth, (user) => {
            if(user) {
                setCurrentUser(user)
                unsubscribes.push(getdbUser(user));
            }
            else{
                setCurrentUser();
                setdbUser({});
                setClubInfo({});
                setRoster({});
                setItems({});
                setRequests({});
            }
            setLoading(false);
        }));
        let unsubFunc = () => {unsubscribes.forEach((func) => {func()})};   //Combines array of unsubscribe functions into one function
        return unsubFunc;
    }, [])

    useEffect(() => {
        let unsubscribes = [];
        if(dbUser.Email){
            //console.log(dbUser)
            unsubscribes.push(getClubInfo());
            unsubscribes.push(getItems("ME"));
            unsubscribes.push(getItems("AVAILABLE"));
            unsubscribes.push(getItems("ME-PENDING"));
            unsubscribes.push(getItems("RESERVED"));
            if(dbUser.isAdmin) {
                unsubscribes.push(getRoster());
                unsubscribes.push(getRequests());
                unsubscribes.push(getItems("INVENTORY"));
            }
            let unsubFunc = () => {unsubscribes.forEach((func) => {func()})};   //Combines array of unsubscribe functions into one function
            return unsubFunc;
        }
    }, [dbUser?.Email, dbUser?.Club, dbUser?.isAdmin])

    /*useEffect(() => {
        console.log(items);
    }, [items])*/

    /*useEffect(() => {
        console.log(requests);
    }, [requests])*/

  const value = {   //TODO: Get rid of any of these which don't need to be exported
    currentUser,
    dbUser,
    clubs,
    clubInfo,
    roster,
    items,
    requests,
    login,
    signup,
    logout,
    resetPassword,
    updateUserProfile,
    changePassword,
    getClubs,
    getClubInfo,
    getItems,
    getRequests,
    getdbUser,
    updateDocument,
    getSetupSheet,
    getReservers,
    clearItems,
    getRoster,
    addField,
    getItemByPath,
    createXLSX,
  }

    /*==================================================Profile Functions==================================================*/
    async function signup(email, password, name, club, UID, phone) {
        let user = {};
        setLoading(true);
        //Capitalize first letter of each word in name. Do not modify any other characters
        const capitalName = name.replace(/(^\w|\s\w)/g, (letter) => letter.toUpperCase())
        return new Promise(async (resolve, reject) => {
            await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {user = userCredential.user; return updateProfile(userCredential.user, {displayName: capitalName, phoneNumber: phone})})
            .then(async () => {
                const data = {
                    "Email":email,
                    "Name": capitalName,
                    "Club": club,
                    "UID": UID,
                    "Phone Number": phone,
                    "authID": user.uid
                }
                await setDoc(doc(store, "users", user.email), data);})
            .then(() => {setSuccess("Welcome to Lendventory!"); setError(""); resolve('user created')})
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                switch(err.code) {
                    case "auth/email-already-in-use":
                        setError("An account associated with this email already exists. Either 'Log In' or use a different email");
                        break;
                    case "auth/invalid-email":
                        setError("Email is badly formatted. Please check for errors and try again");
                        break;
                    case "auth/weak-password":
                        setError("Password too weak. Password should be at least 6 characters. Please try again");
                        break;
                    default:
                        setError("Failed to create account. Please contact your administrator");
                        break;
                }
                reject(err.code);
            })
            .finally(() => {setLoading(false);})
        })
    }

    async function updateUserProfile(name, phone, UID) {
        setLoading(true);
        //Capitalize first letter of each word in name. Do not modify any other characters
        const capitalName = name.replace(/(^\w|\s\w)/g, (letter) => letter.toUpperCase())
        const data = {
            'Name' : capitalName,
            'UID' : UID, 
            'Phone Number' : phone
        }
        return new Promise(async (resolve, reject) => {
            await updateProfile(auth.currentUser, {displayName:capitalName})
            //.then(() => {updatePhoneNumberCredential(auth.currentUser, {phoneNumber: phone});})        //Might be able to combine this line with the previous line
            .then(async () => {await updateDoc(doc(store, "users", auth.currentUser.email), data);})
            .then(() => {setSuccess("Your profile has been successfully updated"); setError(""); resolve('profile updated')})
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Failed to update profile. Please contact your administrator");
                reject(err.code);
            })
            .finally(() => {setLoading(false);})
        })
    }

    async function changePassword(oldPassword, newPassword) {
        setLoading(true);       //TODO: Add promise creation code in all functions which need it
        return new Promise(async (resolve, reject) => {
            const credential = await auth.EmailAuthProvider.credential(currentUser.email, oldPassword);
            await reauthenticateWithCredential(currentUser, credential)
            .then(async () => {await updatePassword(currentUser, newPassword)})
            .then(() => {setSuccess("Password successfully updated"); setError(""); resolve('password updated');})
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                switch(err.code) {
                    case "auth/wrong-password":
                        setError("The current password you entered is incorrect. Please try again.");
                        break;
                    case "auth/weak-password":
                        setError("The new password you entered is too weak. Password should be at least 6 characters. Please try again.");
                        break;
                    default:
                        if(error==="") {setError("An error occured while attempting to update your password. Please contact your administrator.");}
                        break;
                }
                reject(err.code);
            })
            .finally(() => {setLoading(false);});
        })
    }

    async function login(email, password) {
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            await signInWithEmailAndPassword(auth, email, password)
            .then(() => {setSuccess("Welcome to Lendventory!"); setError(""); resolve('user logged in')})
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                //TODO: Check whether these error codes are up to date
                switch(err.code) {
                    case "auth/user-not-found":
                        setError("User does not exist. Please 'Sign Up'");
                        break;
                    case "auth/wrong-password":
                        setError("Incorrect Password. Please try again");
                        break;
                    case "auth/too-many-requests":
                        setError("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or try again later");
                        break;
                    case "auth/invalid-email":
                        setError("Email is badly formatted. Please check for errors and try again");
                        break;
                    case "auth/invalid-credential":
                        setError("Email and/or password is incorrect. Please try again");
                        break;
                    default:
                        setError("Failed to log in. Please contact your administrator")
                        console.log(err.message);
                        break;
                }
                reject(err.code);
            })
            .finally(() => {setLoading(false);});
        })
    }

    async function logout() {
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            await signOut(auth)
            .then(() => {setSuccess("You have been successfully logged out"); setError(""); resolve('user logged out'); navigate("/login");})
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Failed to log out. Please contact your administrator")
                reject(err.code);
            })
            .finally(() => {setLoading(false);});
        })

    }

    async function resetPassword(email) {
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            await sendPasswordResetEmail(auth, email)
            .then(() => {setSuccess("Please check your inbox for password reset email"); setError(""); resolve('password reset')})
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                switch(err.code) {
                    case "auth/user-not-found":
                        setError("User does not exist. Please 'Sign Up'");
                        break;
                    case "auth/invalid-email":
                        setError("Email is badly formatted. Please check for errors and try again");
                        break;
                    default:
                        setError("Failed to reset password. Please contact your administrator");
                        console.log(error.message);
                        break;
                }
                reject(err.code);
            })
            .finally(() => {setLoading(false);});
        })
    }

    /*==================================================Getter Functions==================================================*/

    function getClubs() {
        return onSnapshot(collection(store, "items"), (availableClubs) => {
            let clubList = Object.assign({}, ...availableClubs.docs.map(((club) => { return {[club.id] : club.data()} })));
            if(Object.entries(clubList).length){
                setClubs((clubs) => {return {...clubs, ...clubList}});
            }
        }, (err) => {
            console.log(err);
            console.log(err.message);
        })
    }

    function getdbUser(user) {
        return  onSnapshot(doc(store, "users", user.email), (doc) => {  
            setdbUser(doc.data());
        }, (err) => {
            console.log(err);
            console.log(err.message);
        })
    }

    async function getCollectionList(path) {
        const getSubCollections = httpsCallable(functions, 'getSubCollections');
        return new Promise(async (resolve, reject) => {
            await getSubCollections({docPath: path})
            .then((subCollections) => {
                resolve(subCollections.data.collections);
            })
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                reject(err.code)
            });
        })
    }

    function createXLSX(data) {
        const book = XLSX.utils.book_new();
        Object.entries(data).forEach((dataOfCategory) => {
            const sheetName = dataOfCategory[0];
            const sheetData = dataOfCategory[1];
            //Convert Firestore timestamps into Date() objects
            let sheetDataCleaned = sheetData;
            Object.entries(sheetData).map((row) => {
                const nameData = row[0];
                const rowData = row[1];
                if(rowData["Request Time"]) {rowData["Request Time"] = rowData["Request Time"].toDate()}
                //Delete new and old array fields
                delete rowData.old;
                delete rowData.new;
                //Delete item type field
                delete rowData.Type;
                if(sheetName !== "REQUESTS") {      //BUG: This is not very elegant. Consider changing the name of the fields instead
                //Delete item path field
                    delete rowData.Path;
                }
                return sheetDataCleaned[nameData] = rowData;    //BUG: Not sure if this is a bug
            })
            let sheet;
            if(sheetName === "SETUP") {
                sheet = XLSX.utils.json_to_sheet(Object.values(sheetDataCleaned, {cellDates: true}));
            }
            else if(sheetName === "REQUESTS"){
                const requestHeaders = ["User", "User Email", "User Path", "Name", "Path", "Outstanding Request", "Request Time"];
                sheet = XLSX.utils.json_to_sheet(Object.values(sheetDataCleaned), {header: requestHeaders, cellDates: true});
            }
            else {
                const itemHeaders = ["Manufacturer","Model","ID","Division(s)","Location","Reserver"];
                sheet = XLSX.utils.json_to_sheet(Object.values(sheetDataCleaned), {header: itemHeaders, cellDates: true});
            }            
            XLSX.utils.book_append_sheet(book, sheet, sheetName);
        })
        return book;
    }

    async function getReservers(uid) {  //BUG: Is this function necessary?
        return new Promise(async (resolve, reject) => {
            await getDocs(query(collection(store, "users"), where(new FieldPath('reserved' , uid), "!=", 0)))
            .then((docs) => {
                resolve(docs);
            })
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                reject(err.code)
            });
        })
    }

    function getClubInfo() {
        return onSnapshot(doc(store, "items/" + String(dbUser.Club)), (club) => {
            setClubInfo(club.data());
        }, (err) => {
            console.log(err);
            console.log(err.message);
        })
    }

    function getRoster() {
        return onSnapshot(query(collection(store, "users"), where("Club", "==", dbUser.Club)), (clubMembers) => {
            let members = Object.assign({}, ...clubMembers.docs.map(((member) => { return {[member.id] : member.data()} })));
            if(Object.entries(members).length){
                setRoster((roster) => {return {...roster, ...members}});
            }
        }, (err) => {
            console.log(err);
            console.log(err.message);
        })
    }

    function getSetupSheet(book) {
        const {admins, ...club} = clubInfo
        const members = Object.values(roster).map((user) => {
            return {"Name" : user.Name,
                    "Email" : user.Email,
                    "Phone Number" : user["Phone Number"],
                    "UID" : user.UID
            };
        });

        const clubInfoHeader = [
            ["Club Info"],
            ["Full or Abbreviated",	"Text after the '@' sign", "Admin email", "https://clubsite.com", "Comma separated list", null, null]
        ];
        const adminInfoHeader = [
            ["Admin Info"],
            [null, null, "Country code + 10 digits", "University ID"]
        ];
        const rosterHeader = [
            ["Roster"],
            [null, null, "Country code + 10 digits	University ID"]
        ];

        //Add club header and data
        const clubSheetHeader = XLSX.utils.aoa_to_sheet(clubInfoHeader);
        const clubSheet = XLSX.utils.sheet_add_json(clubSheetHeader, [club], {origin: "A3", header: ["Name","Email Domain","Email","Website","Storage Locations","Discord Webhook URL","Google Sheet URL"]});        
        //Add admin header and data
        const clubAdminSheetHeader = XLSX.utils.sheet_add_aoa(clubSheet, adminInfoHeader, {origin: "A5"})
        const clubAdminSheet = XLSX.utils.sheet_add_json(clubAdminSheetHeader, admins, {origin: "A7", header: ["Email","Name","Phone Number","UID"]})
        //Add roster header and data
        const clubAdminRosterSheetHeader = XLSX.utils.sheet_add_aoa(clubAdminSheet, rosterHeader, {origin: "I1"})
        const setupSheet = XLSX.utils.sheet_add_json(clubAdminRosterSheetHeader, members, {origin: "I3", header: ["Email","Name","Phone Number","UID"]})
        //Merge Header Cells
        setupSheet["!merges"] = [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 4 }, e: { c: 6, r: 4 } },
            { s: { c: 4, r: 5 }, e: { c: 6, r: 16 } },
            { s: { c: 8, r: 0 }, e: { c: 11, r: 0 } }
        ];
        //ADD: Center merged cells, add shading, italics. Community fork with styling required - https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling
        XLSX.utils.book_append_sheet(book, setupSheet, "SETUP");
        return book;
    }

    function getRequests() {
        const path = "requests/" + String(dbUser.Club);
        const requestTypes = ["CHECK-IN", "MOVE", "CHECK-OUT", "RESERVE", "RESERVE-OUT", "RETURN"];
        let unsubscribes = requestTypes.map((requestType) => {  //Returns an array of unsubscribe functions
            const fullPath = path + '/' + String(requestType);
            return onSnapshot(query(collection(store, fullPath), orderBy('Request Time')), (rawRequestsOfType) => {
                let requestsOfType = Object.assign({}, ...rawRequestsOfType.docs.map((request) => { return {[request.id] : request.data()}}));
                //if(Object.entries(requestsOfType).length){
                    setRequests((requests) => {
                        let tempObj = requests;
                        tempObj[requestType] = requestsOfType;
                        return {...tempObj};
                    })
                //}
            }, (err) => {
                console.log(err);
                console.log(err.message);
            })
        })
        let unsubFunc = () => {unsubscribes.forEach(func => func())};   //Combines array of unsubscribe functions into one function
        return unsubFunc;
    }

    function getItems(searchType) {
        setLoading(true);
        let unsubscribes = [];
        const path = "items/" + String(dbUser.Club);
        let queryArray = null;
        switch(searchType) {
            case "INVENTORY":
                break;
            case "AVAILABLE":
                queryArray = ["Reserver", "==", null]
                break;
            case "RESERVED":
                queryArray = ["Reserver", "!=", null];
                break;
            case "ME-PENDING":
                queryArray = ["Outstanding Request", 'in', ['RESERVE', 'RESERVE-OUT']];
                break;
            case "ME":
                queryArray = ["Reserver", "==", String(dbUser.Email)];
                break;
            default:
                break;
        }

        getCollectionList(path).then((itemTypes) => {
            unsubscribes.push(...(itemTypes.map((itemType) => {   //Returns an array of unsubscribe functions
                const fullPath = path + '/' + String(itemType);
                return onSnapshot(((!queryArray) ? collection(store, fullPath) : query(collection(store, fullPath), where(queryArray[0], queryArray[1], queryArray[2]) )), 
                (rawItemsOfType) => {
                    //let itemsOfType = Object.assign({}, ...rawItemsOfType.docs.map((item) => { return {[item.id] : item.data()}}));
                    let itemsOfType = Object.assign({}, ...rawItemsOfType.docs.map((item) => {
                        let itemData = item.data()
                        if(searchType !== "ME-PENDING" || dbUser.Email in itemData.Requestors){
                            return {[item.id] : itemData}
                        }
                    }));
                    //if(Object.entries(itemsOfType).length){
                        setItems((items) => {
                            let tempObj = items;
                            tempObj[searchType] = {...items[searchType], [itemType]: itemsOfType};
                            //console.log(tempObj);
                            return {...tempObj};
                            //return {...items, [searchType] : {...items[searchType], [itemType] : itemsOfType}}
                        });
                    //}
                }, (err) => {
                    console.log(err);
                    console.log(err.message);
                })
            })))
        })
        .catch((err) => {
            setSuccess("");
            console.log(err);
            console.log(err.message);
        })
        .finally(() => {
            setLoading(false);
        })
        let unsubFunc = () => {unsubscribes.forEach(func => func())};   //Combines array of unsubscribe functions into one function
        return unsubFunc;
    }

    async function getItemByPath(path) {
        return new Promise(async (resolve, reject) => {
            await getDoc(doc(store, path))
            .then((doc) => {
                resolve(doc.data());
            })
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                reject(err.code)
            });
        })
    }

    /*==================================================Setter Functions==================================================*/

    async function updateDocument(path, data) {
        return new Promise(async (resolve, reject) => {
            if(data) {
                await setDoc(doc(store, path), data, {merge: true})
                .then(() => {resolve('document updated at path ' + String(path));})
                .catch((err) => {
                    setSuccess("");
                    console.log(err);
                    reject(err.code);
                })
            }
            else {
                await deleteDoc(doc(store, path))
                .then(() => {resolve('document deleted at path ' + String(path));})
                .catch((err) => {
                    setSuccess("");
                    console.log(err);
                    reject(err.code);
                })
            }
        })
    }

    async function addField(itemType, fieldName) {      //TODO: Test & Implement
        const category = "items/" + String(dbUser.Club) + "/" + String(itemType);   //?
        const data = {[fieldName] : null};
        const res = await getDocs(collection(store, category)).catch((err) => console.log(err));
        return Promise.all(res.docs.map(async (doc) => {
            doc.ref.set(data, {merge: true})
        }))
    }

    async function clearItems() {
        const clearSubCollections = httpsCallable(functions, 'clearSubCollections');
        return new Promise(async (resolve, reject) => {
            //Delete items in club inventory
            await clearSubCollections({docPath: "items/" + String(dbUser.Club)})
            //Delete requests made by club members
            .then(async () => {await clearSubCollections({docPath: "requests/" + String(dbUser.Club)})})
            //Delete reserved items from user profiles
            .then(async () => {
                await Promise.all(Object.values(roster).map((member) => {
                    return updateDocument("users/" + String(member.Email), {"reserved" : deleteField()});
                }))
                resolve('items cleared')
            })
            .catch((err) => {
                setSuccess("");
                console.log(err);
                console.log(err.message);
                setError("Failed to clear items. Pleasse contact support.");
                reject(err.code);})
        })
    }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}