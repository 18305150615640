import React from "react";
import { IonIcon } from "@ionic/react";
import {checkmarkCircleOutline, alertCircleOutline} from "ionicons/icons";
import { Button, Table, Accordion, Form, Row, Col } from "react-bootstrap"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";
import { useButton } from "../../../../../contexts/ButtonContext";


export function MyItems() {
    const { currentUser, items, clubInfo } = useAuth()
    const { getName, loading, setCurrentModal, setModalTitle, setModalBody, setModalForm, flattenObject } = useHelp();
    const { selectItem, createRequest, removeRequest, showItemDetailsModal } = useButton()
    const meEmpty = items.ME && (Object.entries(flattenObject(items.ME)).length === 0);
    const mePendingEmpty = items['ME-PENDING'] && (Object.entries(flattenObject(items['ME-PENDING'])).length === 0);

    //TODO: Remove the option to reserve and check-out at once. Require reservation first then check-out

    /*function showReturnModal() {
        //TODO: Change modal to have options make more sense. For now, modal will be skipped. This assumes users do not need instructions to use
        setModalTitle("Check-In or Return?");
        setModalBody("If you would like to forfeit your equipment reservation, choose 'Check-In and Return'. Otherwise, if you would like to retain your reservation store your reserved equipment in the bin, choose 'Check-In Only'.");
        setModalForm(
        <Form id="form" onSubmit={(event) => { //handleResponse(event); event.preventDefault() 
        }}>
            <Form.Control name="reserver" type="hidden" value={currentUser.email}/>
            <Row>
                <Col><Button variant="secondary" type="submit" name="false">Check-In Only</Button></Col>
                <Col><Button variant="primary" type="submit" name="true">Check-In and Return</Button></Col>
            </Row>
        </Form>);
        setCurrentModal("return");
    }*/

    function showCheckInModal(item) {
        setModalTitle("Where would you like your reserved item to be stored?");
        setModalBody("Please select the location where you would like this equpment item to be stored for your use.");
        setModalForm(
            <Form id="form" onSubmit={(event) => {createRequest({...item, newLocation: event.target[0].value}, "CHECK-IN"); event.preventDefault()}}>
                <Row className="d-flex align-items-center">
                    <Col><Form.Label ><strong>Location:</strong></Form.Label></Col>
                    <Col>
                        <Form.Group id="location">
                        <Form.Control as="select" required >
                            {clubInfo?.["Storage Locations"]?.split(',').concat(["TBD", "Broken"]).map( (loc) => {
                                return (<option key={loc} value={loc}>{loc}</option>);
                            })}
                        </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="col-auto">
                        <Button type="submit">
                            Check-In
                        </Button>
                    </Col>
                </Row>
            </Form>);
        setCurrentModal("checkin");
    }

     function showMoveModal(item) {
        setModalTitle("Where would you like to move your reserved item?");
        setModalBody("Please select the new location where you would like this equpment item to be stored for your use. If you select the same location it is already stored, no changes will be made.");
        setModalForm(
            <Form id="form" onSubmit={(event) => {createRequest({...item, newLocation : event.target[0].value}, "MOVE"); event.preventDefault()}}>
                <Row className="d-flex align-items-center">
                    <Col><Form.Label ><strong>Location:</strong></Form.Label></Col>
                    <Col>
                        <Form.Group id="location">
                        <Form.Control as="select" required >
                            {clubInfo?.["Storage Locations"]?.split(',').map( (loc) => {
                                return (<option key={loc} value={loc}>{loc}</option>);
                            })}
                        </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="col-auto">
                        <Button type="submit">
                            Relocate
                        </Button>
                    </Col>
                </Row>
            </Form>);
        setCurrentModal("move");
    }
    
    return(
        <Accordion.Item eventKey="ME">
            <Accordion.Header><h5>My Items</h5></Accordion.Header>
            <Accordion.Body>
                <h3 align="center" className="mb-3">My Items</h3>
                <Accordion>
                    <Accordion.Item eventKey="ME-RESERVED">
                        <Accordion.Header>My Reserved Items</Accordion.Header>
                        <Accordion.Body>
                            <h4>My Reserved Items</h4>
                            {!meEmpty && <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Location</th>
                                        <th colSpan={5} width="1%"/>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.ME && Object.entries(flattenObject(items.ME)).map((item) => {
                                        const outstandingRequest = item[1]["Outstanding Request"];
                                        return (
                                            <tr key={item[0]}>
                                                { (outstandingRequest) ? (<td><IonIcon icon={alertCircleOutline} color={'warning'} size={'large'}/></td>) : (<td><IonIcon icon={checkmarkCircleOutline} color={'success'} size={'large'}/></td>) }
                                                <td>{item[1].Type}</td>
                                                <td>{getName(item[1])}</td>
                                                <td>{(item[1]["Location"] === currentUser.email) ? "Checked Out": item[1]["Location"]}</td>
                                                <td>{(item[1]["Location"] === currentUser.email) ?
                                                    (!outstandingRequest) && <Button disabled={loading} onClick={() => { showCheckInModal(selectItem(item[1])); }}>Check In</Button> :
                                                    (!outstandingRequest) && <Button disabled={loading} onClick={() => { createRequest(selectItem(item[1]), "CHECK-OUT"); }}>Check Out</Button>
                                                }</td>
                                                <td>{(item[1]["Location"] !== currentUser.email) && (!outstandingRequest) && <Button disabled={loading} onClick={() => { showMoveModal(selectItem(item[1], null)); }}>Relocate</Button>}</td>
                                                <td>{(!outstandingRequest) && <Button disabled={loading} onClick={() => { createRequest(selectItem(item[1]), "RETURN"); }}>Return</Button>}</td>
                                                <td>{(outstandingRequest) && <Button disabled={loading} onClick={() => { removeRequest(selectItem(item[1])); }}>Cancel Request</Button>}</td>
                                                <td><Button variant="secondary" disabled={loading} onClick={() => {showItemDetailsModal(selectItem(item[1]))}}>More Details</Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>}
                            {meEmpty && <p className="mt-4" align="center">You do not have any items currently reserved.</p>}
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="ME-PENDING">
                        <Accordion.Header>My Pending Reservations</Accordion.Header>
                        <Accordion.Body>
                            <h4>My Pending Reservations</h4>
                            {!mePendingEmpty && <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Request Type</th>
                                        <th colSpan={2} width="1%"/>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items['ME-PENDING'] && Object.entries(flattenObject(items['ME-PENDING'])).map((item) => {
                                        const outstandingRequest = item[1]["Outstanding Request"];
                                        return (
                                            <tr key={item[0]}>
                                                <td><IonIcon icon={alertCircleOutline} color={'warning'} size={'large'}/></td>
                                                <td>{item[1].Type}</td>
                                                <td>{getName(item[1])}</td>
                                                <td>{outstandingRequest}</td>
                                                <td>{(outstandingRequest) && <Button disabled={loading} onClick={() => { removeRequest(selectItem(item[1])); }}>Cancel Request</Button>}</td>
                                                <td><Button variant="secondary" disabled={loading} onClick={() => {showItemDetailsModal(selectItem(item[1]))}}>More Details</Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>}
                            {mePendingEmpty && <p className="mt-4" align="center">You do not have any items currently pending.</p>}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Accordion.Body>
        </Accordion.Item>
    )
}