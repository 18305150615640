import React from "react";
import { Container } from "react-bootstrap"
import { UserInfo } from "./panels/userInfo";
import { UserItems } from "./panels/userItems";
import { ClubRequests } from "./panels/clubRequests";
import { InventoryImportExport } from "./panels/inventoryImportExport";
import { Help } from "./panels/help";

export default function Dashboard() {
    //TODO: Add google drive functionality
    //TODO: Give users the option to report an issue with equipment
    //TODO: Add reserved time/available time/last transaction time or 'date reserved' to items
    //ADD: Track more actions in analytics
    //ADD: Add option for discord/email notifications for requests and messages (Find free email server)
    //ADD: Allow users to delete their profile and change their email?
    //ADD: Turn errors into real error types
    //TODO: Pause onSnapshots on upload until upload finished
    //TODO: Add flow for broken/unusable items. e.g., "In Circulation" column false
    //BUG: Without requiring users to verify their email, nothing is preventing someone from spoofing a ucla email
    //TODO: Check whether timestamps are handled correctly on upload/download/creation for different time zones
    //BUG: Success messages sometimes do not overwite in order
    //BUG: "__EMPTY" gets added to many of the items - This issues seems to be resolved if your upload spreadsheet has no empty columns
    //BUG: "Outstanding Request" field only shows up in advanced search if the first item in the list is pending
    //ADD: Make advanced search build upon existing search
    //ADD: Use optional chaining instead of && '?.'
    //{clubs[clubRef.currentvalue]?.["Email Domain"]}
    //TODO: Allow for users to be part of multiple clubs

    return(
        <Container className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }} >
            <div className="w-100 mt-5 mb-5" style={{ maxWidth: "400px" }}>
                <UserInfo/>
                <UserItems/>
                <Help/>
                <ClubRequests/>
                <InventoryImportExport/>
            </div>
        </Container>
    );
}