import React from "react";
import { Button, Card, Form } from "react-bootstrap"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useButton } from "../../../../../contexts/ButtonContext";
import { useHelp } from "../../../../../contexts/HelperContext";

export function Help(){
    const { dbUser, clubInfo } = useAuth();
    const { sendDiscordMessage } = useButton();
    const { loading, setError, setSuccess } = useHelp();

    async function handleSubmit(e) {
        e.preventDefault()
    
        if (!e.target[0].value) {
            setSuccess("")
            return setError("Please enter a message before sending")
        }
        else {
            sendDiscordMessage({"message" : e.target[0].value}, "MESSAGE");
            e.target.reset();
        }
    }

    if(!dbUser.isAdmin && clubInfo["Discord Webhook URL"]) {
        return(
            <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Need Help?</h2>
                    <div align="center">
                        <p>Ask your equipment managers!</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Control as="textarea" placeholder="Send us a message!"/>
                            <Button className="w-100 mt-3" disabled={loading} type="submit">Send Message</Button>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
            <br></br>
            </>
        )
    }
}