import React from "react";
import { Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";

export function UserInfo(){
    const {dbUser, logout } = useAuth()
    const { loading } = useHelp()

    return(
        <>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">Hello {dbUser.Name}!</h2>
                <div>
                    <p><strong>Email: </strong>{dbUser.Email}</p>
                    <p><strong>Phone Number: </strong>{dbUser["Phone Number"]}</p>
                    <p><strong>Club: </strong>{dbUser.Club}</p>
                    <p><strong>UID: </strong>{dbUser.UID}</p>
                </div>
                <Button className="w-100 mt-3" disabled={loading} onClick={logout}>Log Out</Button>
                <div className="w-100 text-center mt-2">
                    <Link to="/update-profile"><Button className="w-100 mt-1 mb-1" disabled={loading}>Update Profile</Button></Link>
                </div>
            </Card.Body>
        </Card>
        <br></br>
        </>
    )
}