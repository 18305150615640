import React, { useEffect, useState }  from "react";
import { IonIcon } from "@ionic/react";
import {checkmarkCircleOutline, alertCircleOutline} from "ionicons/icons";
import { Button, Table, Accordion, Row, Col, Form, Dropdown, SplitButton } from "react-bootstrap"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";
import { useButton } from "../../../../../contexts/ButtonContext";

export function AvailableItems() {
    const { currentUser, dbUser, items, roster } = useAuth()
    const { showItemDetailsModal, showAdvancedSearchModal, showReturnLocationModal, AddInput, selectItem, handleResponse, createRequest, removeRequest } = useButton()
    const { getName, loading, setCurrentModal, setModalTitle, setModalBody, setModalForm, flattenObject, sortByMatchQuality, displayInSearch, buildSearchQuery } = useHelp()
    const availEmpty = !items?.AVAILABLE || (Object.entries(flattenObject(items.AVAILABLE)).length === 0);
    const [search, setSearch] = useState("");

    function showReserveModal(item) {
        setModalTitle("Reserve and Check Out?");
        setModalBody("If you would like to store your reserved equipment in the bin, choose 'Reserve Only'. Otherwise, choose 'Reserve and Check Out'. Please note you will be held responsible for all checked out equipment.");
        setModalForm(
        <Form id="form" className="d-flex align-items-center justify-content-center" onSubmit={(event) => {createRequest(item, (event.nativeEvent.submitter.name==="true" ? "RESERVE-OUT" : "RESERVE")); event.preventDefault()}}>
            <Form.Control name="reserver" type="hidden" value={currentUser.email}/>
            <Button className='m-1' variant="secondary" type="submit" name="false">Reserve Only</Button>
            <Button className='m-1' variant="primary" type="submit" name="true">Reserve and Check Out</Button>
        </Form>);
        setCurrentModal("reserve");
    }

    function showAssignModal(item) {
        setModalTitle("Assign Item");
        setModalBody("Select the reserver of this item from the field below.");
        setModalForm(
        <Form id="form" onSubmit={(event) => {handleResponse(selectItem(item), event, "Assign"); event.preventDefault()}}>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column xs={3}><strong>Reserver:</strong></Form.Label>
                <Col><Form.Control as="select" name="reserver" required >
                        {roster && Object.entries(roster).sort((member1, member2) => {return (member1[1].Name).localeCompare(member2[1].Name)}).map((member) => {
                            return (<option key={member[0]} value={member[0]}>{String(member[1].Name) + " (" + String(member[0]) + ")"}</option>);
                        })}
                </Form.Control></Col>
            </Form.Group>
            <Row>
                <Col><Button className="w-100" variant="secondary" type="submit" name="false">Reserve Only</Button></Col>
                <Col><Button className="w-100" variant="primary" type="submit" name="true">Reserve and Check Out</Button></Col>
            </Row>
        </Form>);
        setCurrentModal("assign");
    }

    function showAddModal() {
        //TODO: Make additional fields work
        setModalTitle("Add New Item");
        setModalBody("Use the fields below to add your new item");
        setModalForm(
        <Form id="form" onSubmit={(event) => {/*handleResponse({}, event, "Add");*/ event.preventDefault()}}>
            <Form.Group as={Row} className="mb-2" id="Type" key="Type">
                <Form.Label column><strong>Type:</strong></Form.Label>
                <Col><Form.Control name="Type" type="text" required/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" id="Manufacturer" key="Manufacturer">
                <Form.Label column><strong>Manufacturer:</strong></Form.Label>
                <Col><Form.Control name="Manufacturer" type="text" required/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" id="Model" key="Model">
                <Form.Label column><strong>Model:</strong></Form.Label>
                <Col><Form.Control name="Model" type="text" required/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2" id="ID" key="ID">
                <Form.Label column><strong>Unique ID:</strong></Form.Label>
                <Col><Form.Control name="ID" type="text" required/></Col>
            </Form.Group>
            <AddInput/>
            {/*<Row className="mb-2"><Button variant="secondary" onClick={() => {*//*setExtraInputs(extraInputs + 1); addInput();*//*}}>Add Field</Button></Row>*/}
            <Row className="col-auto"><Button type="submit">Save</Button></Row>
        </Form>);
        setCurrentModal("add");
    }
    
    return(
        <Accordion.Item eventKey="AVAILABLE">
            <Accordion.Header><h5>Available Items</h5></Accordion.Header>
            <Accordion.Body>
                <Row className="mb-2"><Col></Col><Col align="center"><h3>Available Items</h3></Col><Col align="right">{/*dbUser.isAdmin && <Button variant="warning" disabled={loading} onClick={showAddModal}>Add New Item</Button>*/}</Col></Row>
                <Accordion>
                    {!availEmpty && Object.entries(items?.AVAILABLE)?.map((itemsOfType) => {
                        const itemType = itemsOfType[0];
                        if(Object.values(itemsOfType[1]).length){
                            return (
                                <Accordion.Item eventKey={itemType} key={itemType}>
                                <Accordion.Header>{itemType}s</Accordion.Header>
                                <Accordion.Body>
                                <h4>{itemType}s</h4>
                                <Form className="d-flex flex-row mb-2" onSubmit={(event) => {setSearch(buildSearchQuery(event)); event.preventDefault();}} key={search}>
                                    <Form.Control name="Search" type="search" placeholder="Search" defaultValue={search}/>
                                    <SplitButton align="end" title="Search" className="ms-1" type="submit" disabled={loading}>
                                        <Dropdown.Item as="button" className="ms-1 text-nowrap" variant="secondary" disabled={loading} onClick={() => {showAdvancedSearchModal(Object.values(itemsOfType[1])[0], setSearch); }}>Advanced Search</Dropdown.Item>
                                    </SplitButton>
                                </Form>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Name</th>
                                            <th>Location</th>
                                            <th colSpan={5} width="1%"/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(itemsOfType[1]).sort((item1, item2) => sortByMatchQuality(item1, item2, search)).map((item) => {
                                            const outstandingRequest = item[1]["Outstanding Request"];
                                            if(displayInSearch(item, search)){
                                                return (
                                                    <tr key={item[0]}>
                                                        <td>{(outstandingRequest) ? (<IonIcon icon={alertCircleOutline} color={'warning'} size={'large'}/>) : (<IonIcon icon={checkmarkCircleOutline} color={'success'} size={'large'}/>) }</td>
                                                        <td>{getName(item[1])}</td>
                                                        <td>{(item[1]["Location"]?.includes('@')) ? "Checked Out" : item[1]["Location"]}</td>
                                                        <td>{/*!item[1]?.Requestors || !(dbUser.Email in item[1]?.Requestors) &&*/!outstandingRequest && <Button disabled={loading} onClick={() => {showReserveModal(selectItem(item[1])); }}>Request</Button>}</td>
                                                        <td>{dbUser.isAdmin && !outstandingRequest && <Button variant="warning" disabled={loading} onClick={() => {showAssignModal(selectItem(item[1])); }}>Assign</Button>}</td>
                                                        <td>{dbUser.isAdmin && !outstandingRequest && <Button variant="warning" disabled={loading} onClick={() => {showReturnLocationModal(selectItem(item[1])); }}>Relocate</Button>}</td>
                                                        <td>{item[1]?.Requestors && (dbUser.Email in item[1].Requestors) && <Button disabled={loading} onClick={() => { removeRequest(selectItem(item[1])); }}>Cancel Request</Button>}</td>
                                                        <td><Button variant="secondary" disabled={loading} onClick={() => {showItemDetailsModal(selectItem(item[1]))}}>More Details</Button></td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                            </Accordion.Item>
                            );
                        }
                    })}
                    {availEmpty && <p className="mt-4" align="center">Your club does not currently have any items available for reservation.</p>}
                </Accordion>
            </Accordion.Body>
        </Accordion.Item>
    )
}