import React, { useRef } from "react"
import { Container, Form, Button, Card } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { useHelp } from "../../../contexts/HelperContext"

export default function UpdateProfile() {
  const nameRef = useRef(null)    
  const uidRef = useRef(null)
  const phoneRef = useRef(null)
  const oldPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const passwordConfirmRef = useRef()
  const { currentUser, dbUser, updateUserProfile, changePassword } = useAuth()
  const { setError, setSuccess, loading } = useHelp()
  const navigate = useNavigate()

    async function handlePasswordChange(e) {
        e.preventDefault();

        if (!oldPasswordRef.current.value) {
            return setError("Please provide your current password");
        }
        else if (!newPasswordRef.current.value) {
            return setError("Please provide your new password");
        }
        else if (!passwordConfirmRef.current.value) {
            return setError("Please provide your new password again to confirm");
        }
        else if (newPasswordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("New passwords do not match");
        }
        await changePassword(oldPasswordRef.current.value, newPasswordRef.current.value)
        .then(() => {navigate("/");})
        .catch(() => {})
    }

    //TODO: Discriminate by email domain?

    return (
        <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }} >
            <div className="w-100" style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                    <h2 className="text-center mb-4">Update Profile</h2>
                    <Form onSubmit={async (e) => {e.preventDefault(); await updateUserProfile(nameRef.current.value, phoneRef.current.value, uidRef.current.value).then(() => {navigate("/");}).catch()}}>
                        <Form.Group className="mb-2" id="name">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" required ref={nameRef}defaultValue={currentUser.displayName}/>
                        </Form.Group>
                        <Form.Group className="mb-2" id="phone">
                            <Form.Label>Phone Number (Country code + 10 digits)</Form.Label>
                            <Form.Control type="tel" pattern="^\d{11,}$" minLength="11" maxLength="15" required ref={phoneRef} defaultValue={dbUser["Phone Number"]}/>
                            <Form.Text>Please enter digits only, e.g. 18005550175</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2" id="uid">
                            <Form.Label>UID</Form.Label>
                            <Form.Control type="text" required ref={uidRef} defaultValue={dbUser.UID}/>
                            <Form.Text>Please include any leading zeros</Form.Text>
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-3" type="submit">Update</Button>
                    </Form>
                    </Card.Body>
                </Card>
                <br/>
                <Card>
                    <Card.Body>
                    <h2 className="text-center mb-4">Change Password</h2>
                    <Form onSubmit={handlePasswordChange}>
                        <Form.Group className="mb-2" id="old-password">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" required ref={oldPasswordRef} placeholder="Required to change password or delete account"/>
                        </Form.Group>
                        <Form.Group className="mb-2" id="new-password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" ref={newPasswordRef} placeholder="Required to change password"/>
                        </Form.Group>
                        <Form.Group className="mb-2"id="password-confirm">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} placeholder="Required to change password"/>
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-3" type="submit">Change Password</Button>
                    </Form>
                    </Card.Body>
                </Card>
                <div className="w-100 text-center mt-2">
                    <Link to="/">Cancel</Link>
                </div>
            </div>
        </Container>
    )
}