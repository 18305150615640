import React from "react";
import { Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";

export function ClubRequests(){
    const { dbUser, requests } = useAuth()
    const { loading, flattenObject } = useHelp()

    if(dbUser.isAdmin) {
        return(
            <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Requests</h2>
                    <div align="center">
                        <p>You have <strong>{flattenObject(requests) && Object.entries(flattenObject(requests)).length}</strong> equipment request(s) waiting</p>
                        <Link to={"/list"} state={{openPanel: "REQUESTS"}}><Button variant="warning" className="w-100" disabled={loading}>Handle Requests</Button></Link>
                    </div>
                </Card.Body>
            </Card>
            <br></br>
            </>
        )
    }
}