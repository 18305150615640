import Login from './pages/public/Login'
import SignUp from './pages/public/SignUp'
import Dashboard from './pages/private/dashboard/Dashboard'
import ListScreen from './pages/private/list/ListScreen'
import ForgotPassword from './pages/public/ForgotPassword';
import UpdateProfile from './pages/private/UpdateProfile';
import PrivateRoutes from './PrivateRoutes';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import { ButtonProvider } from '../contexts/ButtonContext';
import { HelpProvider } from '../contexts/HelperContext';

function App() {
    return (
        <div className="App">
            <div className="content">
                <Router>
                    <HelpProvider>
                        <AuthProvider>
                            <ButtonProvider>
                                <Routes>
                                    <Route element={<PrivateRoutes />}>
                                        <Route path="/" element={<Dashboard />} />
                                        <Route path="/update-profile" element={<UpdateProfile />} />
                                        <Route path="/list" element={<ListScreen />} />
                                    </Route>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/register" element={<SignUp />} />
                                    <Route path="/forgot-password" element={<ForgotPassword />} />
                                </Routes>
                            </ButtonProvider>
                        </AuthProvider>
                    </HelpProvider>
                </Router>
            </div>
        </div>
    );
}

export default App;
