import React, { useContext, useState, useEffect } from "react"
import { Modal, Spinner, Alert, Button, Form, Col, Row } from "react-bootstrap"
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase"

const HelpContext = React.createContext()

export function useHelp() {
    return useContext(HelpContext);
  }

export function HelpProvider({ children }) {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(true);

    const [currentModal, setCurrentModal] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalForm, setModalForm] = useState(<></>);

    useEffect(() => {
        if(error !== "") {
            logEvent(analytics, 'bug', { message : error});
        }
    }, [error])

    useEffect(() => {
        if(success !== "") {
            logEvent(analytics, 'success', { message : success});
        }
    }, [success])
    
    const value = {
        getName,
        flattenObject,
        error,
        setError,
        success,
        setSuccess,
        loading,
        setLoading,
        currentModal,
        setCurrentModal,
        setModalTitle,
        setModalBody,
        setModalForm,
        sortByMatchQuality,
        displayInSearch,
        buildSearchQuery
    }

    function getName(item) {
        return String(item.Manufacturer) + " " + String(item.Model) + " #" + String(item.ID)
    }

    function flattenObject(obj) {
        return  Object.assign({},...Object.values(obj));
    }

    /////////////////////
    //Search Functionns//
    /////////////////////

    
    function sortByMatchQuality(item1, item2, query) {
        //Sort alphabetically if no query passed
        if(!query) return item1[0].localeCompare(item2[0]);

        const searchTerms = query.replace(/[^a-zA-Z0-9,:.@\- ]/g, " ").split(' ');

        let itemWeight1 = 0;
        let itemWeight2 = 0;
        
        searchTerms.forEach((key) => {
            itemWeight1 += Object.entries(item1[1]).filter((entry) => {
                const numericSearch = !Number.isNaN(key);
                const rangeBounds = String(entry[1]).match(/\d+/g);
                return String(entry[1]).toUpperCase().includes(key.toUpperCase()) || (numericSearch && String(entry[0]).toUpperCase().includes('RANGE') && rangeBounds && ((rangeBounds[0] <= parseFloat(key)) && (parseFloat(key) <= rangeBounds[1])))
            }).length
            if(String(item1[0]).toUpperCase().includes(key)) {itemWeight1++}

            itemWeight2 += Object.entries(item2[1]).filter((entry) => {
                const numericSearch = !Number.isNaN(key);
                const rangeBounds = String(entry[1]).match(/\d+/g);
                return String(entry[1]).toUpperCase().includes(key.toUpperCase()) || (numericSearch && String(entry[0]).toUpperCase().includes('RANGE') && rangeBounds && ((rangeBounds[0] <= parseFloat(key)) && (parseFloat(key) <= rangeBounds[1])))
            }).length
            if(String(item2[0]).toUpperCase().includes(key)) {itemWeight2++}
        })

        return itemWeight2 - itemWeight1
    }

    function displayInSearch(item, query) {
        const strippedItem = JSON.parse(JSON.stringify(item).replace(/ range/gi,'').replace(/\(s\)/gi,''))  //Remove "Range" and "(s)" from field names
        if(query === "") {return true;}
        //TODO: Somehow indicate whether an advanced search or a simple search is being performed
        try {
            const searchObj = JSON.parse("{\"" + query.replace(/:/g, '\":\"').replaceAll(/, */g, ',').replaceAll(",", '\",\"') + "\"}");
            //setSearchType("ADVANCED");    //setting state inside this function throws an error
            return Object.entries(searchObj).every((field) => {
                const numericSearch = !Number.isNaN(field[1]);
                const rangeBounds = String(strippedItem[1][String(field[0])]).match(/\d+/g);
                return String(strippedItem[1][String(field[0])]).toUpperCase().includes(field[1].toUpperCase()) || (numericSearch && rangeBounds && ((rangeBounds[0] <= parseFloat(field[1])) && (parseFloat(field[1]) <= rangeBounds[1])))
            })
        }
        catch(err) {
            //setSearchType("SIMPLE");    //setting state inside this function throws an error
            const searchTerms = query.replace(/[^a-zA-Z0-9,:.@\- ]/g, " ").split(' ');
            //TODO: When re-formatting search term, show new search term in input once button pressed (This currently only works when you change your query other than just removing the special characters)
            return searchTerms.some((key) => {
                return key === "" || String(item[0]).toUpperCase().includes(key.toUpperCase()) || Object.entries(item[1]).some((entry) => {
                    const numericSearch = !Number.isNaN(key);
                    const rangeBounds = String(entry[1]).match(/\d+/g);
                    return String(entry[1]).toUpperCase().includes(key.toUpperCase()) || (numericSearch && String(entry[0]).toUpperCase().includes('RANGE') && rangeBounds && ((rangeBounds[0] <= parseFloat(key)) && (parseFloat(key) <= rangeBounds[1])))
                })
            })
        }
    }

    function buildSearchQuery(formResponse) {
        const formData = Object.fromEntries(new FormData(formResponse.target));
        (formData["Search"] && (/[^a-zA-Z0-9,:.@\- ]/).test(formData["Search"])) ? setError("Special Characters in your search are being ignored") : setError("");
        const queryString = JSON.stringify(formData, (key, value) => {if(value === "") return undefined; else return value;}).replace(/"Search":/, '').replace(/[^a-zA-Z0-9,:.@\- ]/g, '');
        return queryString;
    }

    /*function intersection(obj1, obj2) {
        console.log(obj1)
        console.log(obj2)
        return Object.keys(obj1).filter({}.hasOwnProperty.bind(obj2));
    }*/

    function Message() {
        return(
          <>
            {error && <Alert variant="danger" onClose={() => setError("")} dismissible>{error}</Alert>}
            {success && <Alert variant="success" onClose={() => setSuccess("")} dismissible>{success}</Alert>}
          </>
        )
    }

    function Loading() {
        return (
          <Modal show={loading} data-backdrop="static" data-keyboard="false" centered className="align-items-center justify-content-center">
              <Modal.Header className="align-items-center justify-content-center">
                  <Modal.Title>Loading...</Modal.Title>
              </Modal.Header>
              <Modal.Body align="center">
                  <h4>Please wait...</h4>
                  <Spinner animation="border" role="status"/>
              </Modal.Body>
          </Modal>
        )
    }

    function ModalTemplate() {
        return (
            <Modal show={currentModal !== ""} onHide={() => setCurrentModal("")}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer className="d-flex align-items-center justify-content-center">
                    {modalForm}
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <HelpContext.Provider value={value}>
            <Message/>
            {children}
            <Loading/>
            <ModalTemplate/>
        </HelpContext.Provider>
      )
}