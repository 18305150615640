import React from "react";
import { Button, Card, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";

export function UserItems(){
    const { dbUser } = useAuth()
    const { loading } = useHelp()

    return(
        <>
        <Card>
            <Card.Body>
                <div align="center">
                    <h4>Your Currently Reserved Items:</h4>
                    <Table key="reservedItems">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Type</th>
                                <th>Checked Out?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dbUser.reserved && Object.entries(dbUser.reserved).map((item) => {
                                const itemName = item[0];
                                const itemType = item[1][0];
                                const itemLoaned = item[1][1];
                                return (
                                    <tr key={itemName}>
                                        <td>{itemName}</td>
                                        <td>{itemType}</td>
                                        <td>{itemLoaned ? 'Yes' : 'No'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {(!dbUser.reserved || (dbUser.reserved && !Object.keys(dbUser.reserved).length)) && <p className="mt-4" align="center">You do not have any items currently reserved.</p>}
                </div>
                {dbUser.reserved && Object.keys(dbUser.reserved).length !== 0 && <Link to={"/list"} state={{openPanel: "ME"}}><Button className="w-100 mt-2" disabled={loading}> View Item Details / Return Item(s)</Button></Link>}
                <Link to={"/list"} state={{openPanel: "AVAILABLE"}}><Button className="w-100 mt-3" disabled={loading}>Borrow More</Button></Link>
                <Link to={"/list"} state={{openPanel: "RESERVED"}}><Button className="w-100 mt-3" disabled={loading}>View Currently Unavailable Items</Button></Link>
            </Card.Body>
        </Card>
        <br></br>
        </>
    )
}