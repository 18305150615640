import React, { useRef, useEffect } from "react"
import { Container, Form, Button, Card } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { useHelp } from "../../../contexts/HelperContext"

export default function Signup() {
  const nameRef = useRef()
  const emailRef = useRef()
  const uidRef = useRef()
  const phoneRef = useRef()
  const dobRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup, getClubs, clubs } = useAuth()
  const clubRef = useRef(clubs[0])
  const { setError, loading } = useHelp()
  const navigate = useNavigate()

    useEffect(() => {
        return getClubs();
    }, []);

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }
    const clubEmailDomain = clubs[String(clubRef.current.value)]["Email Domain"];

    if(clubEmailDomain){
      if (!/^[a-zA-Z0-9.-]+$/.test(clubEmailDomain)) {
        return setError("You club admin has incorrectly configured the email domain for your club. Please alert the admin of this issue." )
      }
      const emailDomainRegex = new RegExp("@" + String(clubEmailDomain) + "$");

      if (!emailDomainRegex.test(emailRef.current.value)) { //ADD: Make this validation server-side?
        return setError("Please use your '" + String(clubEmailDomain) + "' email to register" )
      }
    }

    await signup(emailRef.current.value, passwordRef.current.value, nameRef.current.value, clubRef.current.value, uidRef.current.value, phoneRef.current.value)
    .then(() => {navigate("/");})
    .catch()
  }
  //ADD: Only allow people on roster?
  //ADD: Make sign-up options for admins (Email domain requirement/restriction, only allow people on roster, etc.)
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }} >
        <div className="w-100" style={{ maxWidth: "400px" }}>
            <Card>
                <Card.Body>
                <h2 className="text-center mb-4">Sign Up</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2" id="club">
                    <Form.Label className="mt-1">Club</Form.Label>
                    <Form.Control className="mb-1" as="select" ref={clubRef} required >
                        {Object.keys(clubs).map( (club) => {
                            return (<option key={club} value={club}>{club}</option>);
                        })}
                    </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2" id="name">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" ref={nameRef} required />
                    </Form.Group>
                    <Form.Group className="mb-2" id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={emailRef} required />
                    <Form.Text >Please enter your university email</Form.Text>
                    {/*clubs?.[String(clubRef?.current?.value)]?.["Email Domain"] && <Form.Text >Please enter your '{clubs?.[String(clubRef?.current?.value)]?.["Email Domain"]}' email</Form.Text>*//*BUG*/}  
                    </Form.Group>
                    <Form.Group className="mb-2" id="phone">
                    <Form.Label>Phone Number (Country code + 10 digits)</Form.Label>
                    <Form.Control type="tel" pattern="^\d{11,}$" minLength="11" maxLength="15" ref={phoneRef} required />
                    <Form.Text>Please enter digits only, e.g. 18005550175</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-2" id="dob">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="date" ref={dobRef} required />
                    </Form.Group>
                    <Form.Group className="mb-2" id="uid">
                      <Form.Label>UID</Form.Label>
                      <Form.Control type="text" ref={uidRef} required />
                      <Form.Text>Please include any leading zeros</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-2" id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={passwordRef} required />
                    </Form.Group>
                    <Form.Group className="mb-2" id="password-confirm">
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control type="password" ref={passwordConfirmRef} required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">Sign Up</Button>
                </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/login">Log In</Link>
            </div>
        </div>
    </Container>
  )
}