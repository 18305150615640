import React, { useState } from "react";
import { IonIcon } from "@ionic/react";
import {checkmarkCircleOutline, alertCircleOutline} from "ionicons/icons";
import { useAuth } from "../../../../../contexts/AuthContext"
import { useHelp } from "../../../../../contexts/HelperContext";
import { useButton } from "../../../../../contexts/ButtonContext";
import { Button, Table, Accordion, Form, Dropdown, SplitButton } from "react-bootstrap"

export function ReservedItems() {
    const { dbUser, currentUser, items } = useAuth()
    const { showItemDetailsModal, selectItem, showReturnLocationModal, showAdvancedSearchModal, createRequest, removeRequest } = useButton()
    const { getName, loading, flattenObject, sortByMatchQuality, displayInSearch, buildSearchQuery } = useHelp();
    const resEmpty = !items?.RESERVED || (Object.entries(flattenObject(items.RESERVED)).length === 0);
    const [search, setSearch] = useState("");

    function showItem(item) {
        const searchTerms = search.split(' ');
        console.log(searchTerms);
        return search === "" || String(item[0]).toUpperCase().includes(search) || Object.entries(item[1]).some((entry) => {
            const numericSearch = !isNaN(search);
            const rangeBounds = String(entry[1]).match(/\d+/g);
            return String(entry[1]).toUpperCase().includes(search) || (numericSearch && String(entry[0]).toUpperCase().includes('RANGE') && rangeBounds && ((rangeBounds[0] <= parseFloat(search)) && (parseFloat(search) <= rangeBounds[1])))
        })
    }

    return(
        <Accordion.Item eventKey="RESERVED">
            <Accordion.Header><h5>Currently Unavailable Items</h5></Accordion.Header>
            <Accordion.Body>
                <h3 align="center" className="mb-3">Currently Unavailable Items</h3>
                <Accordion>
                    {!resEmpty && Object.entries(items?.RESERVED)?.map((itemsOfType) => {
                        const itemType = itemsOfType[0];
                        if(Object.values(itemsOfType[1]).length){
                            return (
                                <Accordion.Item eventKey={itemType} key={itemType}>
                                    <Accordion.Header>{itemType}s</Accordion.Header>
                                    <Accordion.Body>
                                        <h4>{itemType}s</h4>
                                        <Form className="d-flex flex-row mb-2" onSubmit={(event) => {setSearch(buildSearchQuery(event)); event.preventDefault();}} key={search}>
                                            <Form.Control name="Search" type="search" placeholder="Search" defaultValue={search}/>
                                            <SplitButton align="end" title="Search" className="ms-1" type="submit" disabled={loading}>
                                                <Dropdown.Item as="button" className="ms-1 text-nowrap" variant="secondary" disabled={loading} onClick={() => {showAdvancedSearchModal(Object.values(itemsOfType[1])[0], setSearch); }}>Advanced Search</Dropdown.Item>
                                            </SplitButton>
                                        </Form>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Name</th>
                                                    <th>Location</th>
                                                    <th colSpan={4} width="1%"/>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(itemsOfType[1]).sort((item1, item2) => sortByMatchQuality(item1, item2, search)).map((item) => {
                                                    const outstandingRequest = item[1]["Outstanding Request"];
                                                    const reserverEmail = item[1]["Reserver"];
                                                    //Do not allow for search by reserver if not Admin
                                                    if(!dbUser.isAdmin && reserverEmail !== dbUser.Email) {
                                                        delete item[1]["Reserver"];
                                                        item[1]["Location"] = (item[1]["Location"].includes('@')) ? "Checked Out" : item[1]["Location"];
                                                    }
                                                    if(displayInSearch(item, search)){
                                                        return (
                                                            <tr key={item[0]}>
                                                                { (outstandingRequest) ? (<td><IonIcon icon={alertCircleOutline} color={'warning'} size={'large'}/></td>) : (<td><IonIcon icon={checkmarkCircleOutline} color={'success'} size={'large'}/></td>) }
                                                                <td>{getName(item[1])}</td>
                                                                <td>{item[1]["Location"]}</td>
                                                                <td>{dbUser.isAdmin && !outstandingRequest && <Button variant="warning" disabled={loading} onClick={() => {showReturnLocationModal(selectItem(item[1]));}}>Reclaim</Button>}</td>
                                                                <td>{(reserverEmail === dbUser.Email) && (!outstandingRequest) && <Button disabled={loading} onClick={() => { createRequest(selectItem(item[1]), "RETURN"); }}>Return</Button>}</td>
                                                                <td>{item[1]?.Requestors && (dbUser.Email in item[1].Requestors) && <Button disabled={loading} onClick={() => { removeRequest(selectItem(item[1])); }}>Cancel Request</Button>}</td>
                                                                <td><Button variant="secondary" disabled={loading} onClick={() => {showItemDetailsModal(selectItem(item[1]))}}>More Details</Button></td>
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        }
                    })}
                    {resEmpty && <p className="mt-4" align="center">Your club does not currently have any reserved items.</p>}
                </Accordion>
            </Accordion.Body>
        </Accordion.Item>
    )
}