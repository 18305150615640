import React from "react";
import { useHelp } from "../../../../contexts/HelperContext";
import { Container, Button, Accordion } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { MyItems } from "./accordionPanels/MyItems";
import { AvailableItems } from "./accordionPanels/availItems";
import { ReservedItems } from "./accordionPanels/resItems";
import { Requests } from "./accordionPanels/request";

export default function ListScreen() {
    //ADD: Add filter & search functionality
    //ADD: Allow editing of field names

    const { loading } = useHelp();
    const location = useLocation();
    
    return (
        <Container className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}>
            <div className="w-100 mt-5 mb-5">
                <Accordion defaultActiveKey={location.state.openPanel}>
                    <MyItems/>
                    <AvailableItems/>
                    <ReservedItems/>
                    <Requests/>
                </Accordion>
                <Link to="/"><Button className="w-100 mt-3" disabled={loading}>Back to Dashboard</Button></Link>
            </div>
        </Container>
    )
}